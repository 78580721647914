import "./ThuNghiem.scss";
import React, { useRef, useState } from "react";
import { resizeAnh } from "../component/UploadAnh";

const ThuNghiem = () => {
    const chieuRongMH = window.innerWidth;
    const [anhNew, setanhNew] = useState();
    const handleThemAnh = async (event) => {
        const anhResize = await resizeAnh(event);
        if (anhResize) {
            setanhNew(anhResize);
        }
    };
    console.log("anhNew", anhNew);

    return (
        <div className="view">
            {chieuRongMH <= "1023" && (
                <div className="mobile">
                    <div className="ThuNghiem-ConTaiNer">
                        <div>Thử Nghiệm</div>
                        {/* <div className="uploadAnh-ConTaiNer">
                        <UploadAnh />
                        </div> */}
                        <div className="uploadAnh-container">
                            <input
                                id="idAnh"
                                type="file"
                                name="upload_file"
                                onChange={(event) => handleThemAnh(event)}
                                hidden
                            />
                            <label htmlFor="idAnh" className="uploadAnh">
                                {anhNew ? (
                                    <img src={anhNew} className="anhPreview" />
                                ) : (
                                    <div className="themAnh">
                                        <i className="fa fa-image"></i>
                                    </div>
                                )}
                            </label>
                        </div>

                        <div className="uploadAnh-container2">
                            <input
                                id="idAnh"
                                type="file"
                                name="upload_file"
                                onChange={(event) => handleThemAnh(event)}
                                hidden
                            />
                            <label htmlFor="idAnh" className="uploadAnh">
                                {anhNew ? (
                                    <img src={anhNew} className="anhPreview" />
                                ) : (
                                    <div className="themAnh">
                                        <i className="fa fa-image"></i>
                                    </div>
                                )}
                            </label>
                        </div>
                    </div>
                </div>
            )}
            {chieuRongMH > "1023" && (
                <div className="pc">
                    <div className="ThuNghiem-ConTaiNer">pc</div>
                </div>
            )}
        </div>
    );
};
export default ThuNghiem;
